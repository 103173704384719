<template>
  <div class="ExamResult">
    <detailsHeader
      ref="detailsHeader"
      router-url="examlist"
      :query="{
        examId: $route.query.examId,
      }"
    ></detailsHeader>
    <div class="tab-box">
      <div class="search-box">
        <div class="search-box-item">
          <template v-if="examInformation.id && examInformation.ruleId > 0">
            <!-- <div class="search-item">
              教学班：
              <el-select
                v-model="search.classNums"
                placeholder="请选择"
                filterable
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="(item, i) in classNumList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <div v-if="subjuectClassNumType" class="search-item">
              教学班：
              <el-select
                v-model="search.classNums"
                placeholder="请选择"
                filterable
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="(item, i) in subjectClassNums"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-else class="search-item">
              教学班：
              <el-select
                v-model="search.classNums"
                placeholder="请选择"
                filterable
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="(item, i) in classNumList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="search-item">
              选考组合：
              <el-select
                v-model="search.combination"
                placeholder="请选择"
                filterable
                @change="combinationClick"
              >
                <el-option label="全部" value=""> </el-option>
                <template v-for="(item, i) in combinations">
                  <el-option
                    v-if="item.label.indexOf('物') != -1"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
                <template v-for="(item, i) in combinations">
                  <el-option
                    v-if="item.label.indexOf('历') != -1"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </div> -->
          </template>
          <template v-if="examInformation.id && !examInformation.ruleId">
            <div class="search-item">
              班级：
              <el-select
                v-model="search.classNums"
                placeholder="请选择"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="(item, i) in classNumList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-item">
              考场：
              <el-select
                v-model="search.studentExamRoom"
                placeholder="请选择"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="(item, i) in studentExamRoomsList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </div>
        <div class="search-box-item">
          <el-input
            v-model="search.queryField"
            placeholder="请输入考生姓名"
            @keyup.enter.native="handleClick"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleClick"
            >
            </i
          ></el-input>
          <el-button type="primary" @click="combinatorialQuestionOrder">
            组合题号
            <img src="@/assets/newExam/score/icon-combination.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/score/icon-combination.svg"
              alt=""
            />
          </el-button>
          <el-popover
            placement="bottom"
            width="140"
            trigger="hover"
            popper-class="popover-tips"
            style="margin: 0 18px"
          >
            <div>
              <div
                class="btn-item"
                style="margin-bottom: 16px"
                @click="showImport(0)"
              >
                单科总分
              </div>
              <div
                class="btn-item"
                style="margin-bottom: 16px"
                @click="showImport(1)"
              >
                单科小题
              </div>
              <div class="btn-item" @click="showImport(2)">
                单科小题(含选项)
              </div>
            </div>
            <el-button slot="reference" type="primary">
              导入成绩
              <img src="@/assets/newExam/score/icon-pull-down.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/score/icon-pull-down.svg"
                alt=""
              />
            </el-button>
          </el-popover>

          <el-button type="danger" @click="clearAll">
            清空成绩
            <img src="@/assets/newExam/student/icon-clear.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-clear.svg"
              alt=""
            />
          </el-button>
        </div>
      </div>
      <div class="subject-item-box">
        <div
          v-for="(v, i) in subjectList"
          :key="i"
          :class="{ on: indexPaperId == v.paperId }"
          @click="paperChange(v)"
        >
          {{ v.subjectName }}
        </div>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="dataListLoading"
        border
        :data="dataList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55" align="center"> -->
        <!-- </el-table-column> -->
        <el-table-column
          label="序号"
          prop="studentId"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="学籍号"
          prop="studentNo"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" prop="studentName" label="姓名">
        </el-table-column>
        <el-table-column align="center" prop="studentExamId" label="考号">
        </el-table-column>
        <template v-if="examInformation.id && examInformation.ruleId > 0">
          <el-table-column
            align="center"
            prop="classNum"
            :label="subjuectClassNumType ? '教学班' : '教学班'"
          >
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column align="center" prop="classNum" label="班级">
          </el-table-column>
        </template>

        <el-table-column
          prop="studentExamRoom"
          header-align="center"
          align="center"
          label="考场号"
        />
        <el-table-column
          prop="studentExamSeat"
          header-align="center"
          align="center"
          label="座位号"
        />
        <el-table-column
          prop="studentType"
          header-align="center"
          align="center"
          label="分类"
        />
        <el-table-column
          prop="totalScore"
          header-align="center"
          align="center"
          label="总分"
        >
          <template slot-scope="scope">
            <span class="total-score" @click="showCard(scope.row)">
              {{ scope.row.totalScore || 0 }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <importScore ref="importScore"></importScore>
    <importScoreAll ref="importScoreAll"></importScoreAll>
    <combinationQuestion ref="combinationQuestion"></combinationQuestion>
    <el-dialog title="温馨提示" :visible.sync="delPassword" width="400px">
      <div>
        此操作将永久删除
        <span class="err">仅由Excel导入</span>的
        <span v-if="paperList.length > 0" class="err">
          {{ examInformation.name }}
          {{
            paperList.filter((item) => item.paperId == indexPaperId)[0]
              .subjectName
          }} </span
        >成绩
      </div>
      <div class="password">
        <span>验证登录密码以继续</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="monitorBack"
        ></el-input>
      </div>
      <p style="margin: 18px 0 14px; text-align: center">此操作不可撤销</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delPassword = false">取 消</el-button>
        <el-button type="danger" :loading="modelLoading" @click="monitorBack()">
          清除已导入成绩
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  examstudentscorePagev2,
  clearByPaperId,
  // downloadTemplate,
  // exportAll,
} from "@/core/api/exam/examstudentscore";
import { getObj } from "@/core/api/exam/exam";
import detailsHeader from "@/components/detailsHeader.vue";
import importScore from "./component/examineeScore/importScore.vue";
import importScoreAll from "./component/examineeScore/importScoreAll.vue";
import combinationQuestion from "./component/combinationQuestion.vue";
import { getclassandexamroom } from "@/core/api/exam/examstudent";
import { encryption } from "@/core/util/util";
import { subjectOptions } from "@/core/util/constdata";
export default {
  name: "ExamResult",
  components: {
    detailsHeader,
    importScore,
    importScoreAll,
    combinationQuestion,
  },
  data() {
    return {
      subjectOptions: subjectOptions,
      delPassword: false,
      modelLoading: false,
      indexPaperId: "",
      password: "",
      examInformation: {},
      combinations: [],
      studentExamRoomsList: [],
      subjectClassNums: [],
      classNumList: [],
      paperList: [],
      search: {
        classNums: "",
        combination: "",
        queryField: "",
        studentExamRoom: "",
      },
      //表格列表数据
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      dataList: [],
      dataListLoading: false,
      btnLoading: false,
      subjuectClassNumType: false,
      subjectList: [],
    };
  },
  created() {
    this.getExamInfo();
  },
  methods: {
    combinationClick() {
      let { combination } = this.search;
      if (combination) {
        combination = combination.split("");
        this.subjectList = this.paperList.filter((item) => {
          if (!item.abbreviation) {
            return item;
          } else {
            if (combination.indexOf(item.abbreviation) != -1) {
              return item;
            } else {
              // return false;
            }
          }
        });
      } else {
        this.subjectList = JSON.parse(JSON.stringify(this.paperList));
      }
      this.handleClick();
    },
    clearAll() {
      this.delPassword = true;
      this.password = "";
    },
    clearByPaperId(data) {
      this.modelLoading = true;
      clearByPaperId(data)
        .then(() => {
          this.$message.success("清除全部成绩成功");
          this.getDataList();
          this.modelLoading = false;
          this.delPassword = false;
        })
        .catch(() => {
          this.modelLoading = false;
        });
    },
    // 清除全部
    monitorBack() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        password: user.password,
        paperId: this.indexPaperId,
      };
      this.clearByPaperId(data);
    },
    // 答题卡页面
    showCard(val) {
      // let data = {
      //   studentExamId: val.studentExamId,
      //   studentId: val.studentId,
      //   examId: val.examId,
      //   totalScore: val.totalScore,
      //   studentName: val.studentName,
      //   name: this.examInformation.name,
      //   paperId: this.indexPaperId,
      //   status: this.$route.query.status,
      //   subjectName: this.paperList.filter(
      //     (item) => item.paperId == this.indexPaperId
      //   )[0].subjectName,
      //   urlName: "ExamNewResult",
      //   typeName: this.$route.query.routerName,
      // };
      // let subjectScoreList = [];
      // let paperList = this.paperList.filter(
      //   (index) => index.paperId == this.indexPaperId
      // );

      // subjectScoreList.push({
      //   subjectId: paperList.subjectId,
      //   subjectName: paperList[0].subjectName,
      //   score: val.totalScore,
      // });
      // // console.log(data);
      // data.subjectScoreList = subjectScoreList;
      // let Base64 = require("js-base64").Base64;
      // this.$router.push({
      //   name: "ExamStudentScore",
      //   query: {
      //     code: Base64.encode(JSON.stringify(data)),
      //   },
      // });
      let data = {
        studentId: val.studentId,
        examId: val.examId,
        totalScore: val.totalScore,
        examPaperId: this.indexPaperId,
        urlName: "ExamNewResult",
        typeName: this.$route.query.routerName,
      };

      this.$router.push({
        name: "ExamStudentScore",
        query: data,
      });
    },
    // 展示组合穿梭框
    combinatorialQuestionOrder() {
      this.$refs.combinationQuestion.combinatorialQuestionOrder(
        this.indexPaperId
      );
    },
    // 导入小题分数
    showImport(type) {
      this.$refs.importScore.init(type);
    },
    // 导入小题总分
    showImportAll() {
      this.$refs.importScoreAll.init();
    },
    handleSelectionChange(e) {
      // console.log(e);
      this.checkStudentList = e;
      if (this.checkStudentList.length > 0) {
        this.delStudenttype = true;
      } else {
        this.delStudenttype = false;
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataList = [];
      let search = Object.assign({}, this.search);
      // search.classNums = [search.classNum];
      search = Object.assign(
        {
          current: this.pageIndex,
          size: this.pageSize,
          examId: this.$route.query.examId,
        },
        this.search,
        { examPaperId: this.indexPaperId }
      );
      examstudentscorePagev2(search)
        .then((response) => {
          this.dataList = response.data.data.records;
          this.total = response.data.data.total;
          this.dataListLoading = false;
        })
        .catch(() => {
          this.dataListLoading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getDataList();
      // console.log(`当前页: ${val}`);
    },
    paperChange(value) {
      // this.scoreKey = new Date().getTime();
      this.indexSubjuectId = value.subjectId;
      this.indexPaperId = value.paperId;
      // console.log(value.abbreviation);
      if (value.abbreviation) {
        this.combinations = this.copycombinations.filter((item) => {
          if (item.label.indexOf(value.abbreviation) != -1) {
            return item;
          }
        });
        let array = this.combinations.map((item) => item.label);
        if (array.indexOf(this.search.combination) == -1) {
          this.search.combination = "";
        }
        this.subjuectClassNumType = true;
        this.getnewClassandexamroom();
      } else {
        if (this.examInformation.ruleId) {
          this.combinations = JSON.parse(JSON.stringify(this.copycombinations));
        }

        this.subjuectClassNumType = false;
        this.handleClick();
      }

      // if (value.abbreviation) {
      //   this.combinations = this.copycombinations.filter((item) => {
      //     if (item.label.indexOf(value.abbreviation) != -1) {
      //       return item;
      //     }
      //   });
      //   let array = this.combinations.map((item) => item.label);
      //   if (array.indexOf(this.search.combination) == -1) {
      //     this.search.combination = "";
      //   }
      // } else {
      //   if (this.examInformation.ruleId) {
      //     this.combinations = JSON.parse(JSON.stringify(this.copycombinations));
      //   }
      // }
      // this.pageIndex = 1;
      // this.getDataList();
    },
    // 获取科目班级
    getnewClassandexamroom() {
      let data = {
        subjectId: this.indexSubjuectId,
        combination: this.search.combination,
      };
      getclassandexamroom(this.$route.query.examId, data).then((res) => {
        let { subjectClassNums } = res.data.data;
        this.subjectClassNums = [];
        subjectClassNums.map((item) => {
          this.subjectClassNums.push({
            value: item,
            label: item,
          });
        });
        if (subjectClassNums.indexOf(this.search.classNums) == -1) {
          this.search.classNums = "";
        }
        this.handleClick();
      });
    },
    handleClick() {
      this.scoreKey = new Date().getTime();
      this.pageIndex = 1;
      this.getDataList();
    },
    //获取考试基本信息
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList;
        // 新高考  给选考科目添加标志位
        if (this.examInformation.ruleId) {
          this.paperList.map((item) => {
            this.subjectOptions.map((index) => {
              if (item.subjectId == index.value) {
                if (index.abbreviation) {
                  item.abbreviation = index.abbreviation;
                }
              }
            });
          });
        }
        this.subjectList = JSON.parse(JSON.stringify(this.paperList));
        if (this.$route.query.paperId) {
          this.indexPaperId = this.$route.query.paperId;
        } else {
          this.indexPaperId = this.paperList[0].paperId;
        }
        this.getclassandexamroom();
      });
    },
    // 获取考场列表
    getclassandexamroom() {
      getclassandexamroom(this.$route.query.examId, {}).then((res) => {
        // console.log(res);
        this.classNumList = [];
        this.newClassNumList = [];
        if (res.data.data.classNums) {
          res.data.data.classNums.forEach((index) => {
            this.classNumList.push({
              value: index,
              label: index,
            });
          });
        }
        res.data.data.studentExamRooms.forEach((index) => {
          this.studentExamRoomsList.push({
            value: index,
            label: index,
          });
        });
        this.subjectClassNums = [];
        this.combinations = [];
        if (this.examInformation.examModel) {
          let { subjectClassNums, combinations } = res.data.data;
          subjectClassNums.map((item) => {
            this.subjectClassNums.push({
              value: item,
              label: item,
            });
          });
          combinations.map((item) => {
            this.combinations.push({
              value: item,
              label: item,
            });
          });
          this.copycombinations = JSON.parse(JSON.stringify(this.combinations));
          this.copysubjectClassNums = JSON.parse(
            JSON.stringify(this.subjectClassNums)
          );
        }
        //获取信息
        this.getDataList();
      });
    },
  },
};
</script>
<style lang="scss">
.popover-tips {
  min-width: auto;
  padding: 18px;
  color: #0a1119;
  .btn-item {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
<style scoped lang="scss">
.ExamResult {
  padding: 24px 0;

  .err {
    color: #ff0000;
  }
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
  .total-score {
    color: $primary-color;
    cursor: pointer;
  }
  .subject-item-box {
    background: #ffffff;
    border: 1px solid #ebecee;
    display: flex;
    padding: 12px 18px;
    border-bottom: 0;
    margin-top: 18px;
    div {
      padding: 6px 18px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        color: #2474ed;
        border-color: #2474ed;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    .on {
      color: #2474ed;
      border-color: #2474ed;
    }
  }
  .tab-box {
    margin-top: 18px;
    padding: 18px;
    background-color: #ffffff;
    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f5f7fa;
      padding: 8px 18px;
      .search-box-item {
        display: flex;
        align-items: center;
        .el-input {
          margin-right: 18px;
        }
        .el-button + .el-button {
          margin-left: 18px;
        }
        .search-item {
          margin-right: 18px;
        }
      }
    }
  }
}
</style>
